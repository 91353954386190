html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.crosshairs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5vw;
  height: 5vw;
  border: 1px solid rgb(10, 208, 53);
  box-sizing: border-box;
  pointer-events: none;
}

.crosshairs:before,
.crosshairs:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: rgb(10, 208, 53);
}

.crosshairs:before {
  width: 2px;
  height: 1vh;
  transform: translate(-50%, -50%);
}

.crosshairs:after {
  width: 1vw;
  height: 2px;
  transform: translate(-50%, -50%);
}

.controls-overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30vw;
  height: 25vw;
  background-color: rgba(102, 100, 100, 0.5);
  z-index: 1;
  font-size: 1.2vw;
}
